/* eslint-disable react/no-danger */
import { words } from "lodash"

import { getMsgClient } from "src/lib/stringsUtils"
import SvgIcon from "../SvgIcon"
import { Toast } from "antd-mobile"

export default function ToastMobi(props) {
  const { msg, desc, place, isSuccess = true } = props
  const IS_MOBILE = !!(window.innerWidth < 600)

  const style = {
    maxWidth: IS_MOBILE ? "350px" : "500px",
    minWidth: IS_MOBILE ? "300px" : "320px",

    boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.24)",
    borderRadius: "8px",
    background: isSuccess ? "#E5F5EB" : "#FCCED4",
    justifyContent: "flex-start",
    display: "flex",
    width: "max-content",
  }

  Toast.show({
    className: `notification-custom ${isSuccess ? "success" : "error"}`,
    style,
    position: place || "bottomRight",
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: getMsgClient(msg || ""),
        }}
      />
    ),
    icon: isSuccess ? "success" : "fail",
    duration: 5000,
  })
}
