export const apiGetList = "SystemCate/GetList"
export const apiChangeStatus = "SystemCate/ChangeStatus"
export const apiChangeStatusItem = "SystemCate/ChangeStatusItem"
export const apiChangePermission = "SystemCate/ChangePermission"
export const apiGetListItem = "SystemCate/GetListItem"
export const apiInsertItem = "SystemCate/InsertItem"
export const apiUpdateItem = "SystemCate/UpdateItem"
export const apiDeleteItem = "SystemCate/DeleteItem"
export const apiGetForCombobox = "SystemCate/GetForCombobox"
export const apiImportSystemCate = "SystemCate/ImportSystemCate"
