import {
  apiGetList,
  apiChangeStatus,
  apiChangeStatusItem,
  apiChangePermission,
  apiGetListItem,
  apiInsertItem,
  apiUpdateItem,
  apiDeleteItem,
  apiGetForCombobox,
  apiImportSystemCate,
} from "./urls"
import http from "../index"

const getList = data => http.post(apiGetList, data)
const changeStatus = data => http.post(apiChangeStatus, data)
const changeStatusItem = data => http.post(apiChangeStatusItem, data)
const changePermission = data => http.post(apiChangePermission, data)
const getListItem = data => http.post(apiGetListItem, data)
const insertItem = data => http.post(apiInsertItem, data)
const updateItem = data => http.post(apiUpdateItem, data)
const deleteItem = ItemID => http.patch(`${apiDeleteItem}?input=${ItemID}`)
const getForCombobox = () => http.get(apiGetForCombobox)
const importSystemCate = body =>
  http.post(`${apiImportSystemCate}?CodeKey=${body?.CodeKey}`, body?.formData)

const SystemCateService = {
  getList,
  changeStatus,
  changeStatusItem,
  changePermission,
  getListItem,
  insertItem,
  updateItem,
  deleteItem,
  getForCombobox,
  importSystemCate,
}
export default SystemCateService
